import React from "react"

import { Section } from "../Containers"
import Text from "../Text"
import ImageMeta from "../ImageMeta"

const FeaturedProcedures = ({ heading, blurb, procedures, colorBack }) => {
  return (
    <Section className="featured-procedures" colorBack={colorBack}>
      <div className="columns">
        <div className="column is-3"></div>
        <div className="column">
          <Text as="h3" className="has-text-centered" text={heading} />
          {blurb && <Text className="has-text-centered" text={blurb} />}

          <div className="featured-procedures__grid">
            {procedures.map((item, i) => (
              <div key={i} className="featured-procedures__card">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={item.imageId}
                  responsive
                  width="auto"
                />

                <div>
                  <Text as="h5" text={item.heading} />
                  <Text as="p" text={item.blurb} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="column is-3"></div>
      </div>
    </Section>
  )
}

export default FeaturedProcedures
