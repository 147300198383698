import React from "react"
import { Section } from "../../components/Containers"
import Text from "../../components/Text"

const OurMission = ({ heading, blurb, hasThisSection }) => {
  if (!hasThisSection) return <></>

  return (
    <Section colorBack>
      <div className="columns home-our-mission">
        <div className="column is-3"></div>
        <div className="column" data-aos="fade-up" data-aos-duration="1200">
          <Text as="h2" text={heading} />
          <Text as="p" text={blurb} />
        </div>
        <div className="column is-3"></div>
      </div>
    </Section>
  )
}

export default OurMission
